





























import Vue from "vue";
import { ViewAssessmentsOptions } from "@/interfaces/candidate/canidate_assessments";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  APP_ASSESSMENT_SS,
  APP_ENG_ASSESSMENT_CANCELLED,
  APP_ENG_ASSESSMENT_COMPLETED,
  APP_ENG_CURRENT_QUESTION_INDEX,
  ASSESSMENT_QUESTIONNAIRES,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH,
  CAMERA_RECORDING_SUPPORTED,
  SCREEN_RECORDING_SUPPORTED,
  SUBMIT_APP_ASSESSMENT_ANSWERS
} from "@/store/modules/candidates/constants";
import InterviewLoading from "@/components/candidate/interviews/InterviewLoading.vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import AppEngAssessmentRoot from "@/components/candidate/app_assessments/eng_assessment/AppEngAssessmentRoot.vue";
import {
  APP_ASSESSMENTS,
  APP_ASSESSMENT_RESULT
} from "@/store/modules/common/constants";
import AppAssessmentTermConditions from "@/components/AppAssessmentTermConditions.vue";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { get_random_number_between, wait_until } from "@/utils/global";
import AppResourseNotFound from "@/components/candidate/app_assessments/AppResourseNotFound.vue";
import {
  GET_COMPANY_DETAILS,
  GET_USER_DETAILS
} from "@/store/modules/auth/constants";
import store from "@/store";
import i18n from "@/i18n";
import router from "@/router";
import socketService from "@/services/socket.service";
import { SocketEvents } from "@/interfaces/app.interface";
import domtoimage from "dom-to-image-more";
import { SocketService } from "@/services";

export default Vue.extend({
  name: "InceptionEnglishAssessmentIndex",
  components: {
    AppAssessmentTermConditions,
    InterviewLoading,
    AppEngAssessmentRoot,
    AppResourseNotFound
  },
  data() {
    return {
      data_loading: false,
      data_error: "" as string,
      term_condition_accepted: false,
      screenshot_taken: 0,
      assessment_already_completed: false,
      screen_shots: [] as string[],
      ss_random_number: 15
    };
  },
  async created() {
    this.ss_random_number = get_random_number_between(5, 10);
    // Window unload event
    window.addEventListener("beforeunload", this.beforeUnload, {
      passive: true
    });
  },
  watch: {
    screen_shots(val: string[]) {
      // If screen shots are more than 15 then send the screen shots to server
      if (val.length > this.ss_random_number) {
        const bucket_prefix =
          this.get_company_details?.s3_path?.assessments_path
            .split("/")
            .slice(1)
            .join("/") ?? "open/assessments/inception"; // Bucket prefix
        const assessment_id =
          this.app_eng_assessments.speaking[0].assessment_id;
        const file_name = `user_${this.get_user_details.id}_${assessment_id}_image.png`; // File name
        const assessment_type =
          this.get_company_details?.s3_path?.assessment_setting
            ?.candidates_english; // Assessment type
        // Emitting event to upload screen shots
        socketService.socket.emit(SocketEvents.UPLOAD_SCREEN_SHOTS, {
          screen_shots: val,
          user_id: this.get_user_details.id,
          assessment_id,
          bucket_prefix,
          assessment_type: assessment_type ? assessment_type : "unknown",
          file_name,
          completed: this.assessment_completed,
          cancelled: this.assessment_cancelled
        });
        this.screen_shots = [];
      }
    }
  },
  computed: {
    ...mapGetters("candidate", {
      app_eng_assessments: ASSESSMENT_QUESTIONNAIRES_ENGLISH,
      current_question_index: APP_ENG_CURRENT_QUESTION_INDEX,
      assessment_completed: APP_ENG_ASSESSMENT_COMPLETED,
      assessment_cancelled: APP_ENG_ASSESSMENT_CANCELLED,
      get_camera_recording_supported: CAMERA_RECORDING_SUPPORTED,
      get_screen_recording_supported: SCREEN_RECORDING_SUPPORTED
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    }),
    ...mapGetters("auth", {
      get_company_details: GET_COMPANY_DETAILS,
      get_user_details: GET_USER_DETAILS
    }),
    ViewAssessmentsOptions() {
      return ViewAssessmentsOptions;
    }
  },
  methods: {
    ...mapActions("candidate", {
      fetch_assessment_questionnaries: ASSESSMENT_QUESTIONNAIRES,
      submit_assessment_answers: SUBMIT_APP_ASSESSMENT_ANSWERS
    }),
    ...mapMutations("candidate", {
      set_app_eng_assessments: ASSESSMENT_QUESTIONNAIRES_ENGLISH,
      update_assessment_cancelled: APP_ENG_ASSESSMENT_CANCELLED,
      update_assessment_completed: APP_ENG_ASSESSMENT_COMPLETED,
      upload_assessment_screenshot: APP_ASSESSMENT_SS
    }),
    ...mapActions("common", {
      app_assessment_result: APP_ASSESSMENT_RESULT
    }),
    ...mapMutations({
      set_root_notification: ROOT_NOTIFICATION,
      set_root_error: ROOT_ERROR
    }),
    /**
     *  Function to process term condition accepted or not accepted,
     * if accepted then init function is called to fetch assessment questionnaries
     * else user is redirected to dashboard
     * @param val boolean value to check if term condition is accepted or not
     */
    async process_term_condition(val: boolean) {
      if (!val) {
        await this.$router.push("/candidate/dashboard");
      } else {
        this.term_condition_accepted = true;
        await this.init();
        // Screenshot capture
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (
            this.assessment_cancelled ||
            this.assessment_completed ||
            !this.term_condition_accepted
          )
            break;
          const randomNumber = get_random_number_between(8, 12);
          await wait_until(randomNumber * 1000);
          const img = await domtoimage.toPng(document.body);
          this.screen_shots.push(img);
        }
      }
    },
    /**
     * Function to initialize assessment test by fetching assessment questionnaries
     */
    async init() {
      this.data_loading = true; // Loading until data is fetched
      this.data_error = "";
      const assessment = this.app_assessments.find(
        (val: AppAssessments.Assessment) =>
          val.assessment_type ===
          this.get_company_details.s3_path.assessment_setting.candidates_english
      );
      if (!assessment) {
        this.data_error = this.$t("assessments.not-found").toString();
        this.data_loading = false;
        return;
      }
      // If assessment already exist: return
      const assessment_exist = await this.app_assessment_result({
        assessment_id: assessment.id
      });
      if (assessment_exist) {
        this.set_root_notification(
          `${this.$t("assessments.completed-already")}`
        );
        this.assessment_already_completed = true;
        await this.$router.push("/candidate/dashboard");
        this.data_loading = false;
        return;
      }
      // Fetching assessment questionnaries
      const response = await this.fetch_assessment_questionnaries({
        assessment_id: assessment.id
      });
      // Checking if response is valid or not
      // If not valid then setting error message
      if (!response) {
        this.data_error = this.$t("assessments.failed-to-get").toString();
        this.data_loading = false;
        return;
      }
      const speak_response = response.filter(
        (val: AppAssessments.AssessmentQuestionnaires) =>
          val.question_options &&
          val.question_options.filter === AppAssessments.QuestionFilter.SPEAKING
      );
      const written_response = response.filter(
        (val: AppAssessments.AssessmentQuestionnaires) =>
          val.question_options &&
          val.question_options.filter === AppAssessments.QuestionFilter.WRITTEN
      );
      const speak_final_response = speak_response
        .sort(
          (
            a: AppAssessments.AssessmentQuestionnaires,
            b: AppAssessments.AssessmentQuestionnaires
          ) => {
            return (
              (a.question_options?.order ?? 0) -
              (b.question_options?.order ?? 0)
            );
          }
        )
        .map((val: AppAssessments.AssessmentQuestionnaires) => {
          return {
            ...val,
            answer: null
          };
        });
      const write_final_response = written_response
        .sort(
          (
            a: AppAssessments.AssessmentQuestionnaires,
            b: AppAssessments.AssessmentQuestionnaires
          ) => {
            return (
              (a.question_options?.order ?? 0) -
              (b.question_options?.order ?? 0)
            );
          }
        )
        .map((val: AppAssessments.AssessmentQuestionnaires) => {
          return {
            ...val,
            answer: null
          };
        });
      this.set_app_eng_assessments({
        speaking: speak_final_response,
        speaking_completed: false,
        written: write_final_response,
        filter: ViewAssessmentsOptions.SPEAKING,
        cancelled: false,
        completed: false,
        current_question_index: 0
      });
      this.data_loading = false;
    },
    /**
     * Function to handle window unload event to cancel the assessment if user closes the tab
     * @param e BeforeUnloadEvent
     */
    async beforeUnload(e: BeforeUnloadEvent) {
      const confirmationMessage =
        "Are you sure you want to leave? Your assessment will be cancelled after confirmation.";
      e.returnValue = confirmationMessage;
      // If assessment not completed or not cancelled and term condition is accepted then cancel the assessment
      if (
        !this.assessment_completed &&
        !this.assessment_cancelled &&
        this.term_condition_accepted &&
        !this.assessment_already_completed &&
        this.get_screen_recording_supported &&
        this.get_camera_recording_supported
      ) {
        localStorage.setItem("assessment_cancelled", "1"); // Setting localstorage to check if assessment is cancelled or not
        // Setting payload to cancel the assessment and storing it in localstorage
        const payload = {
          answer: "",
          question_id:
            this.app_eng_assessments.speaking[this.current_question_index].id,
          answer_type:
            this.app_eng_assessments.speaking[this.current_question_index]
              .question_type,
          assessment_id:
            this.app_eng_assessments.speaking[this.current_question_index]
              .assessment_id,
          cancelled: true
        };
        localStorage.setItem("assessment", JSON.stringify(payload));
      }
    },
    // Function to cancel the assessment in DB
    async cancel_assessment() {
      SocketService.socket.emit(SocketEvents.CANCELLED_ASSESSMENT, {
        user_id: this.get_user_details.id,
        assessment_id:
          this.app_eng_assessments.speaking[this.current_question_index]
            .assessment_id
      });
      await this.submit_assessment_answers({
        answer: "",
        question_id:
          this.app_eng_assessments.speaking[this.current_question_index].id,
        answer_type:
          this.app_eng_assessments.speaking[this.current_question_index]
            .question_type,
        assessment_id:
          this.app_eng_assessments.speaking[this.current_question_index]
            .assessment_id,
        cancelled: true
      });
    }
  },
  // Guard to run before navigation leave current route
  async beforeRouteLeave(to, from, next) {
    // If assessment is completed or term condition is not accepted then allow navigation
    if (
      this.assessment_completed ||
      !this.term_condition_accepted ||
      this.assessment_already_completed ||
      !this.get_screen_recording_supported ||
      !this.get_camera_recording_supported
    ) {
      next();
      return;
    }
    // Get confirmation from user => if user cancels navigation => cancel
    if (
      !window.confirm(this.$t("candidate.interview.cancel-heading").toString())
    )
      next(false);
    // If user want to cancel the assessment
    else {
      if (
        this.app_eng_assessments &&
        this.app_eng_assessments.speaking &&
        this.app_eng_assessments.speaking.length
      ) {
        this.set_app_eng_assessments({
          speaking: this.app_eng_assessments.speaking,
          speaking_completed: false,
          written: this.app_eng_assessments.written,
          filter: ViewAssessmentsOptions.SPEAKING,
          cancelled: true,
          completed: false,
          current_question_index:
            this.app_eng_assessments.current_question_index
        });
        this.set_root_error(`${this.$t("assessments.assessment-cancelled")}`);
        await this.cancel_assessment();
        next();
      } else next();
    }
  },
  async beforeRouteEnter(to, from, next) {
    // Find Assessment from store
    const english_assessment = store.getters[`common/${APP_ASSESSMENTS}`].find(
      (val: AppAssessments.Assessment) =>
        val.assessment_type ===
        store.getters[`auth/${GET_COMPANY_DETAILS}`].s3_path.assessment_setting
          .candidates_english
    );
    // Check if english assessment is attempted or not
    if (english_assessment) {
      const assessment_exist = await store.dispatch(
        `common/${APP_ASSESSMENT_RESULT}`,
        {
          assessment_id: english_assessment.id
        }
      );
      // If assessment exist go to dashboard
      if (assessment_exist) {
        store.commit(
          ROOT_NOTIFICATION,
          i18n.t("assessments.completed-already")
        );
        if (router.currentRoute.name === "candidate-dashboard") return;
        await router.push("/candidate/dashboard");
      }
    }
    next();
  }
});
